.menu-button {
  display: flex;
  padding: 4px;
  align-items: center;
  background-color: rgba(204, 204, 204, 0.7);
  border-radius: 4px;
}
.menu-button,
.menu-button * {
  cursor: pointer;
}
.menu-button kbd {
  background-color: #fff;
  border-radius: 3px;
  margin-right: 5px;
  padding: 2px 8px;
  font-size: 1.2rem;
  font-family: "Courier New", Courier, monospace;
}
.menu-button label {
  font-size: 1.2rem;
}