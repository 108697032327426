body {
  transition: ease-in-out background-color 100ms;
}

.fullscreen {
  height: 100vh;
}

.menu-bar {
  display: flex;
  position: fixed;
  inset: auto 0 0 0;
  flex-wrap: wrap;
  align-items: center;
}
.menu-bar > * {
  margin: 5px 8px;
}

.menu-bar-group {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.menu-bar-group > * {
  margin: 5px 8px;
}

.number-indicator {
  background-color: rgba(204, 204, 204, 0.7);
  margin-left: 0px;
  margin-right: 0px;
  padding: 3px 4px;
  border-radius: 4px;
  font-size: 1.2rem;
  font-family: monospace;
}

.nice-test {
  color: red;
}